.nc-MainNav1 {
  &.notOnTop {
    @apply bg-white dark:bg-neutral-900 dark:bg-opacity-60 shadow-sm dark:border-b dark:border-neutral-700;
  }
}

.menu-item.menu-megamenu:hover {
  > .sub-menu {
    @apply visible;
  }
}
